import { push, useState } from 'react'
import { Avatar, Box, Button, Card, CardBody, CardHeader, CardFooter, Collapsible, Header, Heading, Grid, Grommet, Layer, Paragraph, ResponsiveContext, Stack, Text } from 'grommet' 
import { Clear, Configure, FormClose, Notification } from 'grommet-icons'
import PersonGrid from './components/person_display/person_card.js'

const fz_theme = {
    "name": "fz_theme",
    "rounding": 4,
    "spacing": 24,
    "defaultMode": "light",
    "global": {
        "colors": {
            "brand": {
                "dark": "#6E64AE",
                "light": "#37315eFF"
            },
            "background": {
                "dark": "#091109FF",
                "light": "#D8ECD8FF"
            },
            "background-back": {
                "dark": "#0D1A0DFF",
                "light": "#C5E3C5FF"
            },
            "background-front": {
                "dark": "#102210FF",
                "light": "#ECF6ECFF"
            },
            "background-contrast": {
                "light": "#00000011",
                "dark": "#FFFFFF11"
            },
            "text": {
                "dark": "#D8ECD8FF",
                "light": "#1A321A"
            },
            "text-strong": {
                "dark": "#C5E3C5FF",
                "light": "#091109"
            },
            "text-weak": {
                "dark": "#D8ECD8BB",
                "light": "#284328"
            },
            "text-xweak": {
                "dark": "#D8ECD899",
                "light": "#284328AA"
            },
            "border": {
                "dark": "#f46036",
                "light": "#f46036"
            },
            "control": "brand",
                "active-background": "background-contrast",
                "active-text": "text-strong",
                "selected-background": "brand",
                "selected-text": "text-strong",
                "status-critical": "#FF4040",
                "status-warning": "#FFAA15",
                "status-ok": "#00C781",
                "status-unknown": "#CCCCCC",
                "status-disabled": "#CCCCCC",
                "graph-0": "brand",
                "graph-1": "status-warning",
            "focus": "border"
        },
        "font": {
            "family": "Raleway",
            "face": "/* cyrillic-ext */\n@font-face {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 400;\n  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;\n}\n/* cyrillic */\n@font-face {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 400;\n  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCMPrcVIT9d0c-dYA.woff) format('woff');\n  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;\n}\n/* vietnamese */\n@font-face {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 400;\n  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCHPrcVIT9d0c-dYA.woff) format('woff');\n  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;\n}\n/* latin-ext */\n@font-face {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 400;\n  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCGPrcVIT9d0c-dYA.woff) format('woff');\n  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;\n}\n/* latin */\n@font-face {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 400;\n  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrcVIT9d0c8.woff) format('woff');\n  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n}\n"
        },
        "active": {
            "background": "active-background",
            "color": "active-text"
        },
        "hover": {
            "background": "active-background",
            "color": "active-text"
        },
        "selected": {
            "background": "selected-background",
            "color": "selected-text"
        }
    },
    "chart": {},
    "diagram": {
        "line": {}
    },
    "meter": {},
    "layer": {
        "background": {
            "dark": "#111111",
            "light": "#FFFFFF"
        }
    },
    "formField": {
        "border": {
            "color": "brand",
            "error": {
                "color": {
                    "dark": "white",
                    "light": "status-critical"
                }
            },
            "position": "inner",
            "side": "bottom",
            "size": "xsmall",
            "style": "solid"
        },
        "content": {
            "pad": "small"
        },
        "disabled": {
            "background": {
                "color": "text-xweak",
                "opacity": "medium"
            }
        },
        "error": {
            "color": "status-critical",
            "margin": {
                "vertical": "xsmall",
                "horizontal": "small"
            }
        },
        "help": {
            "color": "text-weak",
            "margin": {
                "start": "small"
            }
        },
        "info": {
            "color": "text-xweak",
            "margin": {
                "vertical": "xsmall",
                "horizontal": "small"
            }
        },
        "label": {
            "margin": {
                "vertical": "xsmall",
                "horizontal": "small"
            },
            "weight": "bold",
            "color": "text",
            "size": "medium"
        },
        "margin": {
            "bottom": "small"
        }
    },
    "email": "joe@joeteague.net",
    "date": "2020-11-24T22:33:47.197Z"
}

const AppBar = (props) => (
    <Box
        tag='header'
        direction='row'
        align='center'
        justify='between'
        background='brand'
        pad={{ left: 'medium', right: 'small', vertical: 'small' }}
        elevation='medium'
        style={{ zIndex: '1' }}
        {...props}
    />
);

const test_people = {
    'people': [
    {
        'name': 'Test One',
        'bio': 'I like to do stuff.',
    },
    {
        'name': 'Test One',
        'bio': 'I also like to do stuff. I like to read Edgar Allen Poe and complain about things I don\'t understand.'
    },]
}

function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <Grommet theme={fz_theme} full>
        <ResponsiveContext.Consumer>
            {size => (
            <Box fill>
                <AppBar>
                    <Heading level='3' margin='none'>fz</Heading>
                    <Button icon={ <Notification /> } onClick={() => setShowSidebar(!showSidebar)} />
                </AppBar>

                <Box direction='row' flex overflow={{ horizontal: 'hidden' }} background='background-back'>
                    <PersonGrid
                        people={test_people.people}>
                    </PersonGrid>

                    {(!showSidebar || size !== 'small') ? (
                        <Collapsible direction='horizontal' open={ showSidebar }>
                            <Box flex 
                                width='medium'
                                background='background'
                                elevation='small'
                                align='center'
                                justify='center'
                            >
                                Sidebar
                            </Box>
                        </Collapsible>
                    ): (
                        <Collapsible direction='horizontal' open={ showSidebar }>
                        <Layer
                            animate='true'
                            animation='slide'
                        >
                            <Box
                                background='primary_d'
                                tag='header'
                                justify='end'
                                align='center'
                                direction='row'
                             >
                             <Button
                                icon={<FormClose />}
                                onClick={() => setShowSidebar(false)}
                            />
                            </Box>
                            <Box
                                fill
                                background='primary_l'
                                align='center'
                                justify='center'
                            >
                                Sidebar
                            </Box>
                        </Layer>
                        </Collapsible>
                    )}
                </Box>
            </Box>
            )}
        </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
