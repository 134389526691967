import { push } from 'react'

import {
    Avatar,
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Grid,
    Stack,
    Text,
    Paragraph
} from 'grommet'

import {
    Clear,
    Configure,
    FormClose,
    Notification
} from 'grommet-icons'

const PersonGrid = (props) => (
<Grid 
    align="stretch"
    columns="medium"
    rows='auto'
    margin="medium"
    gap={{"row":"63px","column":"medium"}}
    alignContent="start"
    fill="horizontal"
    {...props}>
    <PersonStack person={{'name': 'test', 'bio': 'test bio'}} />
    <PersonStack person={{'name': 'test', 'bio': 'test bio that needs to extend more than one line so I\'m filling it with nothing important.'}} />
    <PersonStack person={{'name': 'test', 'bio': 'test bio'}} />
    <PersonStack person={{'name': 'test', 'bio': 'test bio'}} />
    <PersonStack person={{'name': 'test', 'bio': 'test bio'}} />
    <PersonStack person={{'name': 'test', 'bio': 'test bio'}} />
    <PersonStack person={{'name': 'test', 'bio': 'test bio'}} />
    <PersonStack person={{'name': 'test', 'bio': 'test bio'}} />
</Grid>
);

const PersonStack = (props) => (
    <Stack guidingChild="first" fill anchor="top">
        <Card
            border={{"color":"border","size":"small"}}
            direction="column"
            margin={{"top":"48px", 'bottom': '-48px'}}
            justify="start"
            align="center"
            flex={true}
            fill='vertical'
            background={{"color":"background-front"}}
            {...props}>
            <CardHeader
                align="center"
                direction="column"
                flex={false}
                justify="center"
                gap="medium"
                fill="horizontal"
                border={{"color":"border","size":"xsmall","side":"bottom"}} 
                pad={{"top":"48px"}}>
                <Text size="xlarge" textAlign="center" weight="bold" color="text-strong">
                    {props.person.name}
                </Text>
            </CardHeader>
            <CardBody
                pad={{"horizontal":"medium","vertical":"small"}}
                background={{"dark":false}}>
                <Paragraph fill size="medium" textAlign="center" color="text">
                    {props.person.bio}
                </Paragraph>
            </CardBody>
            <CardFooter
                align="center"
                background="background-on"
                direction="row-responsive"
                justify="center"
                pad="xsmall"
                border={{"color":"border","side":"top"}}
                fill="horizontal">
                <Button
                    label="Manage"
                    icon={<Configure />}
                    type="button"
                    active={false}
                    size="medium"
                    plain={false}
                    primary
                    fill="horizontal" />
                <Button
                    label="Unfriend"
                    active={false}
                    color="status-critical"
                    plain={false}
                    size="medium"
                    type="button"
                    icon={<Clear />}
                    onClick={() => push("/")} />
            </CardFooter>
        </Card>
        <Avatar
            align="center"
            flex={false}
            justify="center"
            overflow="hidden"
            round="full"
            src="https://rosieshouse.org/wp-content/uploads/2016/06/avatar-large-square-705x705.jpg"
            border={{"color":"border","size":"small"}}
            size="xlarge" />
    </Stack>
);

export default PersonGrid;
